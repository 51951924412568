import React from "react";

import "./button.css";

const BackButton = ({ color, onClick }) => {
  return (
    <div className="back-button" style={{ borderColor: color, color }} onClick={onClick}>
      Go Back
    </div>
  );
};

export default BackButton;