import React from 'react';
import { faGooglePlay } from '@fortawesome/fontawesome-free-brands';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./store-button.css";

const GooglePlay = ({ link }) => {
  return (
    <a href={link} target="_blank" rel="noopener noreferrer">
      <div className="store-button">
        <FontAwesomeIcon icon={faGooglePlay} />
        <span className="label">Play Store</span>
      </div>
    </a>
  );
}

export default GooglePlay;