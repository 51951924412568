import React from 'react';

import './layout.css';

const Column = ({ className, justify, children }) => {
  return (
    <div className={`ns-column ${className || ''} ${justify ? 'justify-'+justify : ''}`}>
      {children}
    </div>
  );
};

export default Column;