import React from 'react';

import './layout.css';

const Row = ({ justify, children }) => {
  return (
    <div className={`ns-row ${justify ? 'justify-'+justify : ''}`}>
      {children}
    </div>
  );
};

export default Row;