import React from 'react';
import { Link } from 'gatsby';

import BackButton from '../components/common/back-button';
import Row from '../components/common/row';
import Column from '../components/common/column';
import AppStore from '../components/common/app-store';
import GooglePlay from '../components/common/google-play';

import "../components/projects/projects.css";

import header from '../images/projects/maleva/header.png';
import screen1 from '../images/projects/maleva/screen1.png';
import screen2 from '../images/projects/maleva/screen2.png';
import screen3 from '../images/projects/maleva/screen3.png';
import Colors from '../constants/Colors';

const MalevaPage = () => {
  return (
    <div className="project-container maleva">
      <div className="content-fluid">
        <Link to="/">
          <BackButton color={Colors.maleva} />
        </Link>
        <div className="header">
          <div className="title">
            Maleva
          </div>
          <Row>
            <Column className="column-padded" justify="center">
              <p className="description">
                Maleva is an application that helps people who have difficulty speaking.
              </p>
            </Column>
            <Column className="image-container">
              <img src={header} alt="Maleva" />
            </Column>
          </Row>
        </div>
        <div className="content">
          <p className="description">
            Maleva has a special meaning for me.
            "Malevo" is my dad, who in his fight against ALS lost the ability to speak.
          </p>
          <p className="description">
          Sometimes it's not easy to communicate with signs and we didn't want to stop talking, so I decided to make an app for him thinking that it could help other people who have the same problem.
          </p>
          <p className="description">
            The first version of the app includes a voice player and you can save the most used words or phrases 
            for quick access next time.  
          </p>
          <p className="description">
            I'll be adding more features soon...
          </p>
        </div>
      </div>
      <div className="gallery">
        <img src={screen1} alt="Maleva" />
        <img src={screen2} alt="Maleva" />
        <img src={screen3} alt="Maleva" />
      </div>
      <div className="footer">
        <div className="content">
          <div className="note">
            This application is available for iOS and Android in Argentina and Uruguay for now.
          </div>
          <div className="download">
            DOWNLOAD
          </div>
          <div className="download-buttons">
            <AppStore link="https://apps.apple.com/us/app/maleva/id1558358902" />
            <GooglePlay link="https://play.google.com/store/apps/details?id=com.ns.maleva.app"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MalevaPage;